import {NavEntry} from 'context/navPropsContext';
import {ITrafficCookieData} from 'hooks/useTrafficSourceCookie';
import {UseMutationResult} from 'react-query';

import {
  Estimation,
  Group,
  PostApiV1PostEstimateReservation400,
  Rate,
  ReservationEstimationDto,
} from 'lib/api/backend.schemas';
import {Reservation} from 'lib/api/backend.schemas';
import {ErrorType} from 'lib/api/mutator/custom-instance';
import {
  SubcomponentsTeaserGridItemComponent,
  SubcomponentsTeaserGridSlideComponent,
} from 'lib/api/strapi';

import {TVarifyTestObject} from 'utils/varify/VarifyContext';

interface RateWithCategory extends Rate {
  item_category: string;
}
type GTMAffiliationType =
  | 'oneway'
  | 'kostenlos_mieten'
  | 'best_price'
  | 'sale'
  | 'tarife'
  | 'longterm';
interface GTMReservationEvent {
  event: string;
  affiliation: string;
  value: number;
  station?: string;
  user_type?: TUserType;
  category?: string;
  kilometer?: number;
  ecommerce: GTMEcommerceItem[] | {items: GTMEcommerceItem[]};
  step?: string;
}
type TUserType = 'guest' | 'register_account' | 'existing_account';
interface GTMEcommerceItem {
  item_id: string;
  item_name: string;
  item_category: string;
  item_category2?: string;
  item_category3?: string;
  item_variant?: string;
  price: number | string;
  quantity: number;
}
type EstimationQueryType = UseMutationResult<
  Estimation,
  ErrorType<void | PostApiV1PostEstimateReservation400>,
  {
    reservation: string;
    data: ReservationEstimationDto;
  },
  unknown
>;
export const getUserTypeFromReservation = (reservation: Reservation): TUserType => {
  if (reservation?.createLogin) {
    return 'register_account';
  } else if (reservation?.customer?.id) {
    return 'existing_account';
  } else {
    return 'guest';
  }
};
export const getSubratesFromReservation = (reservation: Reservation) => {
  const {subrates} = reservation;

  const activeSubrates = Object.entries(subrates)
    .map(([key, value]) => value.map(item => ({...item, item_category: key})))
    .reduceRight((acc, item) => [...acc, ...item], [])
    .filter(subrate => subrate.count > 0);
  return activeSubrates as RateWithCategory[];
};

export const getItemsArrayForGTMFromSubrates = (
  subrates: RateWithCategory[],
): GTMEcommerceItem[] => {
  const subratesForGTM = subrates.map(({item_category, ...subrate}: RateWithCategory) => {
    return {
      item_id: subrate.name,
      item_name: subrate.description,
      item_category,
      price: subrate.price,
      quantity: 1,
    };
  });
  return subratesForGTM;
};
export const getAvailabilityStatus = (lowAvailability: boolean): string => {
  return lowAvailability ? 'lowAvailability' : 'normalAvailability';
};

export const getGroupItemFromReservation = (reservation: Reservation) => {
  const {group} = reservation;
  return {
    item_id: group?.id,
    item_name: reservation?.mainRate?.name || group?.description,
    item_category: group?.category, // optional
    item_category2: group?.type, //optional
    item_category3: getAvailabilityStatus(group?.lowAvailability),
    item_variant: reservation?.mainRate?.name || undefined, // optional
    price: reservation?.mainRate?.price ? reservation?.mainRate?.price : (0 as number),
    quantity: 1,
  };
};
export const getOnewayEcommerceItems = ({
  rate,
  groupId,
  category,
}: {
  rate: Rate;
  groupId: string;
  category: string;
}) => {
  return {
    item_id: groupId,
    item_name: rate.name,
    item_category: category, // optional
    item_category2: null, //optional
    item_variant: rate.name || undefined, // optional
    price: rate.price,
    quantity: 1,
  };
};
export const getItemsArrayForGTMReservation = (reservation: Reservation) => [
  getGroupItemFromReservation(reservation),
  ...getItemsArrayForGTMFromSubrates(getSubratesFromReservation(reservation)),
];
export type TReservationMetaData = {
  rentalDays: number;
  /**
   * ISO date string in the format 'YYYY-MM-DD', e.g., '2023-11-08'
   */
  arrival: `${number}-${number}-${number}`;
  /**
   * ISO date string in the format 'YYYY-MM-DD', e.g., '2023-11-10'
   */
  departure: `${number}-${number}-${number}`;
  /**
   * Day of the week as a number (0 for Sunday, 6 for Saturday)
   */
  arrivalWeekday: number;
  /**
   * Day of the week as a number (0 for Sunday, 6 for Saturday)
   */
  departureWeekday: number;
  /**
   * Time in the format 'HH:MM:SS', e.g., '15:04:05'
   */
  arrivalTime: `${string}:${string}`;
  /**
   * Time in the format 'HH:MM:SS', e.g., '15:04:05'
   */
  departureTime: `${string}:${string}`;
};

export const getDateMetaDataFromReservation = (reservation: Reservation): TReservationMetaData => {
  const arrivalDate = new Date(reservation?.arrival);
  const departureDate = new Date(reservation?.departure);
  const formatDate = (date: Date): `${number}-${number}-${number}` => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}` as `${number}-${number}-${number}`;
  };

  return {
    rentalDays:
      Math.floor((arrivalDate.getTime() - departureDate.getTime()) / (1000 * 3600 * 24)) + 1,
    arrival: formatDate(arrivalDate),
    departure: formatDate(departureDate),
    departureWeekday: arrivalDate.getDay(),
    arrivalWeekday: departureDate.getDay(),
    arrivalTime: `${arrivalDate.getHours().toString().padStart(2, '0')}:${arrivalDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}`,
    departureTime: `${departureDate.getHours().toString().padStart(2, '0')}:${departureDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}`,
  };
};
export const beginCheckoutDatalayer = (
  reservation: Reservation,
  estimationMethods?: EstimationQueryType,
): GTMReservationEvent => {
  const subrateItems = getItemsArrayForGTMReservation(reservation);
  const {data} = estimationMethods;

  return {
    event: 'begin_checkout',
    affiliation: reservation?.affiliation || getReservationType(reservation),
    ecommerce: {items: subrateItems},
    value: data?.total,
    user_type: getUserTypeFromReservation(reservation),
  };
};

export const addToCartDatalayer = (
  reservation: Reservation,
  estimationMethods?: EstimationQueryType,
): GTMReservationEvent => {
  const subrateItems = getItemsArrayForGTMReservation(reservation);
  const {data} = estimationMethods;
  return {
    event: 'add_to_cart',
    station: reservation?.station?.id,
    category: reservation?.category,
    kilometer: reservation?.distance,
    affiliation: reservation?.affiliation || getReservationType(reservation),
    ecommerce: {items: subrateItems},
    value: data?.total,
    step: '3',
  };
};
export const addShippingInfoDatalayer = (
  reservation: Reservation,
  user_type: TUserType,
  estimationMethods?: EstimationQueryType,
): GTMReservationEvent => {
  const subrateItems = getItemsArrayForGTMReservation(reservation);
  const {data} = estimationMethods;

  return {
    event: 'add_shipping_info',
    station: reservation?.station?.id,
    category: reservation?.category,
    kilometer: reservation?.distance,
    user_type,
    affiliation: reservation?.affiliation || getReservationType(reservation),
    ecommerce: {items: subrateItems},
    value: data?.total,
    step: '4',
  };
};
export const getTrafficDataFromReservation = (reservation: Reservation) => {
  let res: ITrafficCookieData = {traffic_source: 'direct'};
  if (reservation?.trafficData) {
    res = {
      traffic_campaign: reservation?.trafficData?.trafficCampaign,
      traffic_medium: reservation?.trafficData?.trafficMedium,
      traffic_source: reservation?.trafficData?.trafficSource || 'direct',
    };
  }
  return res;
};
export const purchaseDatalayer = (reservation: Reservation) => {
  const subrateItems = getItemsArrayForGTMReservation(reservation);
  const reservationMeta = getDateMetaDataFromReservation(reservation);
  const trafficData = getTrafficDataFromReservation(reservation);
  return {
    event: 'purchase',
    value: reservation?.finalTotal,
    user_type: getUserTypeFromReservation(reservation),
    affiliation: reservation?.affiliation || getReservationType(reservation),
    step: '5',
    ...trafficData,
    ecommerce: {
      partner_id: reservation?.partnerId || 'no-id',
      transaction_id: reservation.id,
      tax: reservation?.finalEstimation?.total - reservation?.finalEstimation?.netTotal,
      shipping: 0,
      currency: 'EUR',
      payment_type: reservation.customer?.payment?.type,
      reservierungsnummer: reservation?.bookingNumber,
      kundenkategorie:
        reservation?.customer?.type === 'Login'
          ? reservation?.customer?.isBusinessClient
            ? 'company'
            : 'private'
          : reservation?.customer?.type?.replace('customer.type.', ''),
      gruppe: reservation?.group?.id,
      rate: reservation?.mainRate?.name,
      abholstation: reservation?.station?.id,
      kilometer: reservation?.distance,
      items: subrateItems,
      ...reservationMeta,
    },
  };
};
interface IGroupWithPrice extends Group {
  price?: boolean | string;
}
export const bestPriceGroupCardFlip = (
  group: IGroupWithPrice,
  affiliation: string,
  rateName?: string,
): GTMReservationEvent => {
  if (group?.longterm) affiliation = 'longterm';
  // item_name = gruppen ID
  return {
    event: 'view_item',
    affiliation: affiliation, // oneway, kostenlos_mieten, best_price, sale

    step:
      affiliation.includes('tarife') ||
      affiliation.includes('cts') ||
      affiliation.includes('kostenlos_mieten')
        ? '1'
        : '2',
    value: 0,
    ecommerce: {
      items: [
        // Fahrzeug + Zusatzbuchungen
        {
          item_id: group?.id,
          item_name: group?.description,
          item_category: group?.category, // optional
          item_variant: rateName || undefined,
          item_category2: group?.type, // optional
          item_category3: getAvailabilityStatus(group?.lowAvailability),
          price: group?.price ? (group?.price as string) : (0 as number),
          quantity: 1,
        },
      ],
    },
  };
};

export const getReservationType = (reservation: Reservation): GTMAffiliationType => {
  if (reservation.sale) return 'sale';
  if (reservation.oneWay) return 'oneway';
  if (reservation.rentForFree) return 'kostenlos_mieten';
  return 'best_price';
};

// Define types for each argument
export type TMenuDatalayerType = 'header' | 'burger';
export type TDatalayerMenuStyle = 'mobile' | 'desktop';

export interface IHeaderMenuDataLayerArgs {
  detail: string; // Bezeichnung des Header-Elements
  target: string; // URL des Buttons
  position: number; // Position des Header-Elements
  menustyle: TDatalayerMenuStyle;
  type: TMenuDatalayerType;
}

// Define return type for the function
export interface IHeaderMenuDataLayerReturn {
  event: 'navigation';
  TMenuDatalayerType: TMenuDatalayerType;
  TDatalayerMenustyle: TDatalayerMenuStyle;
  edetail: string;
  etarget: string;
  eposition: number;
}
export interface HeaderMenuLinkProps extends NavEntry {
  index: number;
  menustyle: TDatalayerMenuStyle;
  type: TMenuDatalayerType;
}
// Function to push the dataLayer event
export const getHeaderMenuDataLayer = ({
  href,
  index,
  title,
  type,
  menustyle,
}: HeaderMenuLinkProps): IHeaderMenuDataLayerReturn => {
  return {
    event: 'navigation', // Fest definiertes Event darf nicht angepasst werden
    TMenuDatalayerType: type, // Definition der Navi
    TDatalayerMenustyle: menustyle, // Style of menu (mobile/desktop)
    edetail: title, // Bezeichnung des Header-Elements
    etarget: href, // URL des Buttons
    eposition: index, // Position des Header-Elements
  };
};
interface INewFeaturesModalDataLayerProps {
  action: 'view' | 'click' | 'close';
  page: number;
  type: string;
  detail?: string;
  experiment?: TVarifyTestObject;
}
export const getNewFeaturesModalDataLayer = ({
  action,
  page,
  type,
  detail,
  experiment,
}: INewFeaturesModalDataLayerProps) => {
  return {
    event: 'features',
    eaction: action, // "view", bei jeder Impression pro Seite, "click" beim Klick auf einen Button, "close", wenn auf "X" rechts obeb geklickt wurde
    epage: page, // Auf welcher (numerischen) Seite befinden sich Nutzer:innen
    etype: type, // Welches Feature wird auf der Seite vorgestellt. Beispiel. One way im neuen Gewand
    edetail: detail, // Bezeichnung / Klicktext der geklicken Buttons
    variation_id: experiment?.id ? experiment?.variationId || 'Original' : undefined, // variationId aus Varify
    experiment_id: experiment?.id, // experiment ID aus Varify
  };
};

interface TTeaserGridDataLayer {
  event: 'slider';
  edetail: string; // Description of the slider
  etarget: string; // URL of the slider
  eposition: number; // Position of the header element (0, 1, 2 from left to right)
  esliderposition: number; // Position within the slider of the first card (0-3)
}
export interface ITeaserGridDataLayerProps {
  gridComponent: SubcomponentsTeaserGridItemComponent | SubcomponentsTeaserGridSlideComponent;
  index: number;
  sliderPosition?: number;
}
export const getTeaserGridDataLayer = ({
  gridComponent: {link},
  index,
  sliderPosition,
}: ITeaserGridDataLayerProps): TTeaserGridDataLayer => {
  const href = link?.page?.data?.attributes?.fullSlug || link?.link || '';
  return {
    event: 'slider',
    edetail: link.linkText,
    etarget: href,
    eposition: index,
    esliderposition: typeof sliderPosition !== 'undefined' ? sliderPosition : null,
  };
};
type TKLMDataLayerProps = {
  cityType: 'pickupStations' | 'returnStations';
  city: string;
  category?: 'lkw' | 'pkw' | null;
  types?: string[];
};
export const getKLMDataLayer = ({
  cityType,
  city,
  category = null,
  types = [],
}: TKLMDataLayerProps) => {
  const cityTypeForDataLayer = cityType === 'pickupStations' ? 'pickup' : 'return';
  return {
    event: 'rent_for_free',
    etype: cityTypeForDataLayer, // Auswahl return oder pickup (einheitlich auf englisch, fass möglich)
    epickup: cityTypeForDataLayer === 'pickup' ? city : undefined, //  Auswahl Stadt
    ereturn: cityTypeForDataLayer === 'return' ? city : undefined, // Auswahl Stadt
    category, // Auswahl LKW / PKW
    vehicle_type: types.join(','), // Auswahl Fahrzeugtyp
  };
};

type TFeedbackDataLayerProps = {
  efeedback: string;
};
export const getFeedbackDataLayer = ({efeedback = null}: TFeedbackDataLayerProps) => {
  return {
    event: 'feedback',
    efeedback,
  };
};
