import {useMemo} from 'react';

import {useGetApiV1Stations} from 'lib/api/backend';
import {Station} from 'lib/api/backend.schemas';

export const useStations = (presetGroup: string | null, includeHidden?: boolean | null) => {
  const {
    isLoading,
    isSuccess,
    isError,
    data: stations = [],
  } = useGetApiV1Stations({
    withDatepickerData: true,
    groupId: presetGroup || null,
    includeHidden: includeHidden || false,
  });

  const stationsById = useMemo(() => {
    if (isLoading || isError) return {};
    return stations.reduce((acc: {[key: string]: Station}, station: Station) => {
      acc[station.id] = station;
      return acc;
    }, {});
  }, [isLoading, isError, stations]);

  const oneWayStationsCar = useMemo(() => {
    if (isLoading || isError) return [];
    return stations.filter(station => station.oneWayCar);
  }, [isLoading, isError, stations]);

  const oneWayStationsTruck = useMemo(() => {
    if (isLoading || isError) return [];
    return stations.filter(station => station.oneWayTruck);
  }, [isLoading, isError, stations]);

  return {isLoading, isSuccess, isError, stations, stationsById, oneWayStationsCar, oneWayStationsTruck};
};
